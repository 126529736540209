.custom-pagination .page-link {
    color: #332d2d; /* Cor dos números e símbolos */
}

.custom-pagination .page-link:hover {
    color: #332d2d; /* Cor ao passar o mouse */
}

.custom-pagination .page-item.active .page-link {
    background-color: #332d2d; /* Cor de fundo da página ativa */
    border-color: #332d2d; /* Cor da borda da página ativa */
    color: white; /* Cor do texto da página ativa */
}